import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Box, Button, Typography } from "@mui/material";
import Logo from "../components/logo.png";
import Modal from "react-bootstrap/Modal";
import GoogleIcon from "@mui/icons-material/Google";
import FacebookIcon from "@mui/icons-material/Facebook";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function ResponsiveAppBar() {
  const [show, setShow] = useState(false);
  const [showLogin, setShowLogin] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseLogin = () => setShowLogin(false);
  const handleShowLogin = () => setShowLogin(true);
  const navigate = useNavigate();
  const navigateToContacts = () => {
    navigate("/contacts");
  };
  const navigateToHome = () => {
    navigate("/");
  };
  return (
    <>
      <Grid container sx={{ padding: "20px" }}>
        <Grid item md={0.5} sx={{ textAlign: "start" }}>
          <img
            src={Logo}
            style={{ width: "60px", height: "55px" }}
            onClick={navigateToHome}
          />
        </Grid>
        <Grid item md={1} sx={{ textAlign: "start" }}>
          <Typography
            variant="h6"
            noWrap
            component="a"
            onClick={navigateToHome}
            sx={{
              cursor: "pointer",
              fontFamily: "monospace",
              fontWeight: 900,
              letterSpacing: ".1rem",
              fontSize: "30px",
              color: "#794c1d !important",
              textDecoration: "none",
              marginLeft: "10px",
            }}
          >
            MySkyFence
          </Typography><br/>
          <Typography
            variant="h6"
            noWrap
            component="a"
            onClick={navigateToHome}
            sx={{
              cursor: "pointer",
              fontFamily: "monospace",
              fontWeight: 900,
              letterSpacing: ".1rem",
              fontSize: "14px",
              color: "#794c1d !important",
              textDecoration: "none",
              marginLeft: "10px",
            }}
          >
          (by eVEGU)
          </Typography>
        </Grid>
        <Grid item md={5.5}></Grid>
        </Grid>
        {/* <Grid item md={5}>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}></Box>
        </Grid>
      </Grid>
      <Modal show={show} onHide={handleClose} className="Modal">
        <Modal.Header closeButton>
          <Modal.Title>SIGNUP USING</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col></Col>
            <Col>
              {" "}
              <GoogleIcon />
            </Col>
            <Col>
              {" "}
              <FacebookIcon />
            </Col>
            <Col></Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showLogin} onHide={handleCloseLogin}>
        <Modal.Header closeButton>
          <Modal.Title>LOGIN WITH</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <GoogleIcon />
          <FacebookIcon />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseLogin}>
            Close
          </Button>
        </Modal.Footer>
      </Modal> */}
    </>
  );
}
export default ResponsiveAppBar;

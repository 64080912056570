import React from "react";
import Carousel from "react-bootstrap/Carousel";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Customcard from "./custom/customcard";

function Slides() {
  return (
    <>

      <Row className="Slides" >
      <Col ></Col>
        <Col md={11}>
    <Carousel className="Carousel">
      <Carousel.Item>
        {/* <Container>
          <Row>
            <Col md={12}> */}
            <Customcard
              CardContentHeader="Stay Informed"
              CardContent="Track construction progress, land encroachment, and environmental changes with regular, artificial intelligence-enhanced high-resolution satellite updates"
              fontSize='21px'
            />
            {/* </Col>           
          </Row>  
          </Container>       */}
      </Carousel.Item>
      <Carousel.Item>
      {/* <Container>
          <Row>
            <Col md={12}> */}
            <Customcard
              CardContentHeader="Make Informed Decisions"
              CardContent="Gain valuable insights for informed land management, planning, and investment decisions"
              fontSize='21px'
            />
            {/* </Col>
            </Row>
            </Container> */}
      </Carousel.Item>
      <Carousel.Item>
      {/* <Container>
          <Row>
            <Col md={12}> */}
            <Customcard
              CardContentHeader="Peace of Mind"
              CardContent=" Rest assured knowing your land is safe and secure with remote monitoring via satellites as frequent as twice a month"
              fontSize='21px'
            />
            {/* </Col>
            </Row>
            </Container> */}
      </Carousel.Item>
    </Carousel>
    </Col>
    <Col></Col>
      </Row>
   
    </>
  );
}

export default Slides;

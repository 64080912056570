import React from 'react';
import { Typography, CircularProgress } from '@mui/material';
import AppBar from './AppNavbar';

// const useStyles = makeStyles((theme) => ({
//     root: {
//         display: 'flex',
//         flexDirection: 'column',
//         alignItems: 'center',
//         justifyContent: 'center',
//         height: '100vh',
//     },
//     progress: {
//         marginBottom: theme.spacing(2),
//     },
// }));

const InProgress = () => {
    //const classes = useStyles();

    return (
        <>
        <AppBar />
        <div style={{marginLeft:'700px', marginTop:'100px'}}>
            
            <CircularProgress />
            <Typography variant="h5" component="h2">
                Work in Progress
            </Typography>
            <Typography variant="body1" component="p">
                This page is currently under development.
            </Typography>
        </div>
        </>
    );
};

export default InProgress;
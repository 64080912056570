import React from 'react'
import AppBar from './AppNavbar'
const ContactUs = () =>{
    return(
        <>
         <AppBar/>
        Contact
        </>
    )

}
export default ContactUs;
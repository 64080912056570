import React,{ useState,useRef  } from "react";
import { MapContainer, TileLayer ,FeatureGroup ,useMap } from "react-leaflet";
//import osmProviders from "./osm-providers";
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
//import { useMapEvents, Polygon } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
//import { OpenStreetMapProvider } from "react-leaflet-geosearch";
import L from 'leaflet'
import SearchControl from './SearchControl';
import "leaflet/dist/leaflet.css";

const BasicMap = ({getLatlong}) => {
  const [center, setcenter] = useState({ lat: 12.8907773, lng:77.6484794 });
  const ZOOM_LEVEL = 9;
  const [searchQuery, setSearchQuery] = useState("");

const handleCreated = (e) => {
  const { layerType, layer } = e;
  if (layerType === 'rectangle') {
    const latLngs = layer.getLatLngs()[0]; // Assuming the first array contains the rectangle coordinates
    let maxLat = -Infinity, minLat = Infinity, maxLng = -Infinity, minLng = Infinity;

    latLngs.forEach((latLng) => {
      if (latLng.lat > maxLat) maxLat = latLng.lat;
      if (latLng.lat < minLat) minLat = latLng.lat;
      if (latLng.lng > maxLng) maxLng = latLng.lng;
      if (latLng.lng < minLng) minLng = latLng.lng;
    });

    console.log(`Max Latitude: ${maxLat}, Min Latitude: ${minLat}, Max Longitude: ${maxLng}, Min Longitude: ${minLng}`);
    // You can also pass these values to the getLatlong function if needed
    getLatlong({maxLat, minLat, maxLng, minLng});
  }
  console.log("Created:", layerType, layer.getLatLngs());
};
  const handleEdited = (e) => {
    const { layers } = e;
    layers.eachLayer((layer) => {
      console.log("Edited:", layer.getLatLngs());
      
    });
  };
  return (
    <>
      <MapContainer center={center} zoom={ZOOM_LEVEL}>

      <ReactLeafletGoogleLayer  
       apiKey= "AIzaSyCxAWUDFwakXDLneahBUUBgdesioq1G2Ag"
       type={'roadmap'}
      />
      <FeatureGroup>
        <EditControl
          position='topright'
          draw={{
            circle: false,
            rectangle: true,
            polyline: false,
            marker: false,
            circlemarker: false,
            polygon: false,
          }}
          edit={{
            edit: true,
            remove: true,
          }}
          onCreated={handleCreated}
          onEdited={handleEdited}
        />
      </FeatureGroup>          
      </MapContainer>
    </>
  );
  }

export default BasicMap;

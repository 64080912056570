
import React , {useState} from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import {useNavigate} from "react-router-dom";

function ButtonCards(props) {
  const navigate = useNavigate();
  const CardBtnClick =()=>{
    if(props.CardButton=="Monitor"){
      navigate('/monitor');
    }
    if(props.CardButton=="Check"){
      navigate('/workinprogress');
    }
    if(props.CardButton=="Explore"){
      navigate('/workinprogress');
    }
    if(props.CardButton=="View"){
      navigate('/workinprogress');
    }

  }
  return (
    <Card className="ButtonCard">
      {/* <Card.Header>Featured</Card.Header> */}
      <Card.Body>
        <Card.Title>{props.CardTiltle}</Card.Title>
        <Card.Text className='ButtonCardText'>
        {props.CardText}
        </Card.Text>
        <Button variant="primary" className='ButtonCardAction' onClick={CardBtnClick}>{props.CardButton}</Button>
      </Card.Body>
      <Card.Footer className="text-muted">{props.CardFooter}</Card.Footer>
    </Card>
  );
}

export default ButtonCards;

import React, { useState, useEffect } from "react";
import AppBar from "./AppNavbar";
import Map from "./Map";
import { Grid, Box, Paper } from "@mui/material";
import { Avatar, Typography, Stack } from "@mui/material";
import Profile from "./Profile";
import PropTypes from "prop-types";
import MonitoringDashboard from "./PlotMonitor/MonitoringDashborad";
const Monitorplot = () => {
  return (
    <>
      <AppBar />
      <Grid container spacing={0}>
        <Grid item md={9}></Grid>
        <Grid item md={0.8}>
          <Profile />
        </Grid>
        <Grid item md={2} sx={{textAlign:'end'}}>
          <Typography sx={{ textAlign: "start", marginTop: "10px" ,fontWeight:900 , wordWrap:'break-word !important'}}>
            Welcome
          </Typography>
          <Typography sx={{ textAlign: "start",fontWeight:700 , wordWrap:'break-word !important'}}>
            {localStorage.getItem('profileNameObj')}
          </Typography>
        </Grid>
       
      </Grid>
      <Grid container spacing={0}>
        <Grid item md={2}></Grid>
        <Grid item md={8}>
          <Typography
            sx={{ textAlign: "center", fontSize: "27px", color: "#794c1d", fontWeight:800 }}
          >
          Plot|Land Monitoring 
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid item md={1}></Grid>
        <Grid item md={10}>
          <Typography
            sx={{ textAlign: "center", fontSize: "23px", color: "#794c1d" , marginTop:'20px'}}
          >
            Stay connected to your ancestral/invested land in India, no matter
            the distance. eVEGU uses cutting-edge technology to give you the
            power to see every detail, right from your device
          </Typography>

         
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid item md={1}></Grid>
        <Grid item md={10}>
        <MonitoringDashboard/>
        </Grid>
      </Grid>
    </>
  );
};
export default Monitorplot;

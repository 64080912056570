import React from 'react';
import AppBar from './AppNavbar'

const About = () =>{
    return(
        <>
        <AppBar/>
        About Us
        </>
    )
}
export default About;
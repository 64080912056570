// GoogleAuth.js

import React ,{useState}from 'react';
import { GoogleLogin } from '@leecheuk/react-google-login';
import { Dialog, DialogTitle, DialogContent, DialogActions , Button} from "@mui/material";
const clientId = '551064649681-3iq4h8su27u8h80oqahtja8bhiu8fp2p.apps.googleusercontent.com'; // Replace with your own Client ID

const GoogleAuth = (props) => {

    // Handle successful authentication here, e.g., store the user data in state
    const [userData, setUserData] = useState(undefined);
    const [open, setOpen] = useState(props.open);
  
   
    const handleClose = () => {
      setOpen(false);
      props.handleClose(false);
    };

    const onSuccess = (response) => {
      console.log('Login Success:', response);
      setUserData(response);
      props.userData(response)
      props.handleClose(false);
    };
    
  

  function onFailure(error) {
    console.error('Login Failure:', error);
    props.handleClose(false);
    // Handle authentication failure here
  }
console.log(userData)
  return (
    <div>
     
       <Dialog open={props.open} onClose={handleClose}>
      <DialogTitle  sx={{textAlign:'center', textTransform:'uppercase',fontWeight:800, marginTop:'30px'}}> Sign Up / Login</DialogTitle>
      <DialogContent className='LoginTileContent'  sx={{width:'500px', height:'200px', textAlign:'center',marginTop:'0px'}}>
      <GoogleLogin
        clientId={clientId}
        buttonText="Login with Google"
        onSuccess={onSuccess}
        onFailure={onFailure}
        //cookiePolicy={'single_host_origin'}
      />
      </DialogContent>
      <DialogActions sx={{textAlign:'start'}}>
        <Button onClick={handleClose} >Cancel</Button>
      </DialogActions>
    </Dialog>
    </div>
  );
};

export default GoogleAuth;

import React ,{useState} from "react";
import BasicMap from "./BasicMap";
import { Grid, Box, Paper, Typography, InputLabel, Button } from "@mui/material";
import PlotImg from "../../Images/FeaturedPlot.png";
import { useNavigate } from "react-router-dom";
const FeaturedPlot = (props) => {
const [plotImage, setPlotImage] = useState(null);
  return (
    <>
{plotImage ? (
   
      <Grid container spacing={0}>
        <Grid item md={6}>
          <Paper elevation={4} sx={{ backgroundColor: "#f6e9e7" }}>
            <Box
              component="img"
              sx={{
                height: " auto",
                padding: 0,
                margin: 0,
                width: "100%",
                maxWidth: "100%",
              }}
              src={PlotImg}
            />
          </Paper>
        </Grid>
        <Grid item md={6}>
          <Paper
            elevation={4}
            sx={{ backgroundColor: "#f6e9e7", marginLeft: "10px" }}
          >
            <Box
              //component="img"
              sx={{
                height: " auto",
                padding: 0,
                margin: 0,
                width: "100%",
                maxWidth: "100%",
                padding:'25px'
              }}
            >
              <Typography variant="h6" sx={{fontWeight:600}}>Plot Details</Typography>
              <Grid container spacing={2}>
                <Grid item md={4}>
                  <InputLabel sx={{fontWeight:600}}>Plot Name :</InputLabel>
                </Grid>
                <Grid item md={6}>
                  <InputLabel >HYD1</InputLabel>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={4}>
                  <InputLabel sx={{fontWeight:600}}>Owner Name :</InputLabel>
                </Grid>
                <Grid item md={6}>
                  <InputLabel>Aravind Ch</InputLabel>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={4}>
                  <InputLabel sx={{fontWeight:600}}>Address :</InputLabel>
                </Grid>
                <Grid item md={6}>
                  <InputLabel>Near Madapur</InputLabel>
                  <InputLabel>Ranga Reddy Dist</InputLabel>
                  <InputLabel>Telangana</InputLabel>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={4} >
                  <InputLabel sx={{fontWeight:600}}> Plot Image Captured On :</InputLabel>
                </Grid>
                <Grid item md={6}>
                  <InputLabel>07-03-2024</InputLabel>
                </Grid>
              </Grid>
              
            </Box>
          </Paper>
        </Grid>
      </Grid>
):( <> 
 <Grid container spacing={0}>
  <Grid item md={6} sx={{marginTop:'30px'}}>
    <Typography variant="h6" sx={{fontWeight:600}}>Please add plot details to avail this feature</Typography>
    </Grid>
    <Grid item md={6}>    
    </Grid>
    </Grid>
   <Button className="ButtonCardAction" sx={{color:'white',marginTop:'30px'}} onClick={() => props.value(1)}>Go to AddPlotDetails</Button>
</>
)}
    </>
  );
};

export default FeaturedPlot;

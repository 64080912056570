import axios from 'axios';

    const GetUsers = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL+'users');
            // Handle the response data here
            console.log(response.data);
        } catch (error) {
            // Handle the error here
            console.error(error);
        }
    };
    const CreateUser = async (user) => {
        try {
            console.log(user)
            const response = await axios.post(process.env.REACT_APP_API_URL+'users', user);
            // Handle the response data here
            console.log(response.data);
        } catch (error) {
            // Handle the error here
            console.error(error);
        }
    };

    const UpdateUser = async (userId, updatedUser) => {
        try {
            const response = await axios.put(process.env.REACT_APP_API_URL+`users/${userId}`, updatedUser);
            // Handle the response data here
            console.log(response.data);
        } catch (error) {
            // Handle the error here
            console.error(error);
        }
    };

    const DeleteUser = async (userId) => {
        try {
            const response = await axios.delete(process.env.REACT_APP_API_URL+`users/${userId}`);
            // Handle the response    GetUsers();
            console.log(response.data);
        }
        catch (error) {
            // Handle the error here
            console.error(error);
        }
    }
    const CreatePlotDetails = async (plot) => {
        try {
            console.log(process.env.REACT_APP_API_URL)
            const response = await axios.post(process.env.REACT_APP_API_URL+'plotdetails', plot);
            // Handle the response data here
            console.log(response.data);
        } catch (error) {
            // Handle the error here
            console.error(error);
        }
    };
    export { GetUsers, CreateUser, UpdateUser, DeleteUser ,CreatePlotDetails};
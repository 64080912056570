import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
const Customcard = (props) => {
  const [fontSize, setFontSize] = useState('21px');

  const TakeAction = () => {
    props.ReturnAction();
  };

  useEffect(() => {
    setFontSize(props.fontSize);
  }, [props.fontSize]);

  return (
    <>
      <Row>
        <Col xs={12} md={12} lg={12}>
          <Card
            style={{
              width: '100%',
              height: '150px',
              padding: '20px',
            }}
            className="CustomCard"
          >
            <Card.Text className="CustomCardHeader">{props.CardContentHeader}</Card.Text>
            <Card.Text className="CustomCardText" style={{ fontSize: fontSize }}>
              {props.CardContent}
            </Card.Text>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Customcard;

import React,{ useState, useEffect } from "react";
import AppBar from "../AppNavbar";
import {
  Grid,
  Typography,
  Paper,
  InputLabel,
  Box,
  Button,
} from "@mui/material";
import Customcard from "../custom/customcard";
import RealPlot from "../../Images/RealPlot.png";
import UserContext from "../../UserProfileContext";
import GoogleAuth from "../../GAuth";
import { useNavigate } from "react-router-dom";
import { CreateUser } from "../APIservices/CallAPIs";

const Monitor = () => {
  const [userData, setUserData] = useState(false);
  const [open, setOpen] = useState(false);
 
  const handleUserData = (data) => {
  const expiresAt = new Date(data.tokenObj.expires_at * 1000).toISOString();
  console.log(expiresAt);
    setUserData(data);
    let userdataInsert ={
      name: data.profileObj.name,
      email: data.profileObj.email,
      username: data.profileObj.name,
      access_token: data.accessToken,
      expires_in: data.tokenObj.expires_in,

    }
    CreateUser(userdataInsert)
      .then(response => {
        // Handle success
        console.log(response);
      })
      .catch(error => {
        // Handle error
        console.error(error);
      });
    console.log(data);
    localStorage.setItem("profileNameObj", data.profileObj.name);
    localStorage.setItem("profileEmailObj", data.profileObj.email);
    localStorage.setItem("profileimageUrlObj", data.profileObj.imageUrl);
    localStorage.setItem("accessTokenObj", data.accessToken);
    localStorage.setItem("accessTokenObjExp", data.tokenObj.expires_at);
  };
  const navigate = useNavigate();
  useEffect(() => {
    const accessTokenObjExp = localStorage.getItem("accessTokenObjExp");
    if (accessTokenObjExp) {
      // Check if access token is expired
      const isExpired = isTokenExpired(accessTokenObjExp);
      console.log(isExpired);
      if (isExpired) {
      } else {
        navigate("/monitorplot");
      }
    }
  }, [userData]);

  const isTokenExpired = (expiresAt) => {
    // Your code here
    const currentTime = Date.now();
    return currentTime >= expiresAt; // Return true if token is expired, false otherwise
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <UserContext.Provider value={userData}>
        <AppBar />
        <Grid container spacing={0}>
          <Grid item md={2}></Grid>
          <Grid item md={8}>
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "27px",
                color: "#794c1d",
                fontWeight: 800,
              }}
            >
              Plot|Land Monitoring
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item md={1.5}></Grid>
          <Grid item md={9}>
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "23px",
                color: "#794c1d",
                marginTop: "20px",
              }}
            >
              Stay connected to your ancestral/invested land in India, no matter
              the distance. eVEGU uses cutting-edge technology to give you the
              power to see every detail, right from your device
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item md={1.5}></Grid>
          <Grid item md={9}>
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "40px",
                color: "#794c1d",
                marginTop: "50px",
                fontWeight: 800,
              }}
            >
              How it works?
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1} sx={{ marginTop: "30px" }}>
          <Grid item md={1}></Grid>
          <Grid item md={3.5}>
            <Paper elevation={4}>
              <Customcard
                CardContentHeader="Step 1"
                CardContent=" Register your land/plot details"
                fontSize="18px"
              />
            </Paper>
          </Grid>

          <Grid item md={3.5}>
            <Paper elevation={4}>
              <Customcard
                CardContentHeader="Step 2"
                CardContent=" Add geo coordinates manually or using our mapping tool"
                fontSize="18px"
              />
            </Paper>
          </Grid>
          <Grid item md={3.5}>
            <Paper elevation={4}>
              <Customcard
                CardContentHeader="Step 3"
                CardContent="Choose a subscription plan to monitor your land with satellite images"
                fontSize="18px"
              />
            </Paper>
          </Grid>
          <Grid item md={1}></Grid>
        </Grid>
       
        <Grid container spacing={2} sx={{ marginTop: "10px" }}>
          <Grid item md={3}>
         
          </Grid>
          <Grid item md={4}>
          {/* <Container>
            <img src={BenefitsImage} alt="Plot Image" height={'220px'}/>
          </Container> */}
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "25px",
                fontWeight: "700",
                color: "#794c1d",
                marginTop: "20px",
                textTransform: "uppercase",
              }}
            >
              Get exclusive benefits upon launch
            </Typography>
          </Grid>
          <Grid item md={4}>
          <Button
              variant="contained"
              className="ButtonCardAction"
              sx={{ fontSize: "20px !important" ,marginTop:'10px'}}
              onClick={handleOpen}
            >
              Sign up / Login
            </Button>
            </Grid>
        </Grid>

        {/* <Grid container spacing={2} sx={{ marginTop: "30px" }}>
          <Grid item md={3}></Grid>
          <Grid item md={6}>
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "23px",
                color: "#794c1d",
                marginTop: "20px",
                textTransform: "uppercase",
              }}
            >
              Sample plot
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          sx={{ marginTop: "10px", marginBottom: "20px" }}
        >
         <Grid item md={1}></Grid>
      
          <Grid item md={10}>
            <Paper elevation={1} sx={{ backgroundColor: "#f6e9e7" }}>
              <Box
                sx={{
                  height: " auto",
                  padding: 0,
                  margin: 0,
                  width: "100%",
                  maxWidth: "100%",
                }}
              >
                SatSure Image
              </Box>
            </Paper>
          </Grid>
          <Grid item md={1}></Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          sx={{ marginTop: "10px", marginBottom: "20px" }}
        >
         <Grid item md={1}></Grid>
        <Grid item md={10}>
            <Paper
              elevation={4}
              sx={{ backgroundColor: "#f6e9e7", padding: "20px" }}
            >
              <Grid container spacing={2}>
                <Grid item md={3}>
                  <InputLabel sx={{ fontWeight: 600 }}>Plot Name :</InputLabel>
                </Grid>
                <Grid item md={6}>
                  <InputLabel>HYD1</InputLabel>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={3}>
                  <InputLabel sx={{ fontWeight: 600 }}>Owner :</InputLabel>
                </Grid>
                <Grid item md={6}>
                  <InputLabel>Aravind Ch</InputLabel>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={3}>
                  <InputLabel sx={{ fontWeight: 600 }}>Address :</InputLabel>
                </Grid>
                <Grid item md={6}>
                  <InputLabel>Near Madapur</InputLabel>
                  <InputLabel>Ranga Reddy Dist</InputLabel>
                  <InputLabel>Telangana</InputLabel>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={3}>
                  <InputLabel sx={{ fontWeight: 600 }}>
                    Coordinates :
                  </InputLabel>
                </Grid>
                <Grid item md={8}>
                  <InputLabel>
                    {" "}
                    "lat": 17.450252712966915, "lng": 78.39127079934278
                  </InputLabel>
                  <InputLabel>
                    {" "}
                    "lat": 17.45027011259312, "lng": 78.39142369795793
                  </InputLabel>
                  <InputLabel>
                    {" "}
                    "lat": 17.450200514117363, "lng": 78.39143764659535a
                  </InputLabel>
                  <InputLabel>
                    {" "}
                    "lat": 17.45018106746188, "lng": 78.39127670068467
                  </InputLabel>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item md={1}></Grid>
        </Grid> */}
        {open ? (
          <>
            {/* <Login/> */}
            <GoogleAuth
              open={open}
              handleClose={handleClose}
              userData={handleUserData}
            />
          </>
        ) : null}
      </UserContext.Provider>
    </>
  );
};

export default Monitor;

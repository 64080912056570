import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AddPlotDetails from "./AddPlotDetails";
import FeaturedPlot from "./FeaturedPlot";
function MonitoringDashboard() {
  const classes = "useStyles()";
  const [value, setValue] = useState(0);
  const handleValue = (val) => {
    setValue(val);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        orientation="horizontal"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        className={classes.tabs}
      >
        <Tab label="Featured Plot"  sx={{fontWeight:800}}/>
        <Tab label="Add Plot Details"  sx={{fontWeight:800}}/>
      </Tabs>
      {value === 0 && <FeaturedPlot value={handleValue} />}
      {value === 1 && <AddPlotDetails />}
    </div>
  );
}
export default MonitoringDashboard;

import React from 'react';
import { GoogleLogout } from '@leecheuk/react-google-login';
import { useNavigate } from 'react-router-dom';
const GoogleLogoutButton = ({ clientId }) => {

    const navigate = useNavigate();
  const logoutSuccess = () => {
    localStorage.clear();
    navigate('/monitor');
    console.log('Logout successful');
  };

  return (
    <GoogleLogout
      clientId={clientId}
      buttonText="Logout"
      onLogoutSuccess={logoutSuccess}
    />
  );
};

export default GoogleLogoutButton;

import React from "react";
import "./App.css";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ContactUs from "./components/ContactUs";
import Home from "././components/Home";
import About from "./components/AboutUs";
import Monitorplot from "./components/Monitorplot";
import Monitor from "./components/PlotMonitor/Monitor";
import AddPlotDetails from "./components/PlotMonitor/AddPlotDetails";
import WorkInProgress from "./components/InProgress";

const theme = createTheme({
  typography: {
    fontFamily: `'Open Sans', sans-serif !important`,
  },
});
function App() {
  return (
    <>
    
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
              <Route path="/" element={<Home />} />          
              <Route path="contacts" element={<ContactUs />} />
               <Route path="about" element={<About />} />
               <Route path="monitor" element={<Monitor />} />
               <Route path="monitorplot" element={<Monitorplot />} />
               <Route path="addplotdetails" element={<AddPlotDetails/>} />
               <Route path="workinprogress" element={<WorkInProgress />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </>
  );
}

export default App;

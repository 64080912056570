import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/esm/Button";
import YouTubeIcon from "@mui/icons-material/YouTube";
import XIcon from "@mui/icons-material/X";
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
const Footer = () => {
  return (
    <>
      <Container className="Footer" fluid>
        <Row>
          <Col md={12} style={{border:'1px solid #794c1d', marginTop:'30px'}}></Col>
        </Row>
        <Row  style={{ marginTop:'30px'}}>
          <Col md={4}></Col>        
          <Col md={1} style={{marginLeft:'60px'}}>
            <InstagramIcon style={{color:'#794c1d'}}/>
          </Col>
          <Col md={1}>
            <FacebookIcon style={{color:'#794c1d'}}/>
          </Col>
          <Col md={1}>
            <XIcon style={{color:'#794c1d'}}/>
          </Col>
          <Col md={1}>
            {" "}
            <YouTubeIcon style={{color:'#794c1d'}}/>
          </Col>
          <Col md={3}></Col>
        </Row>
        <Row>
        <Col md={4}></Col>
          <Col md={5} style={{color:'#794c1d',marginTop:'20px', marginBottom:'10px',marginLeft:'80px'}}>@2024 Vapusha Inc | All Rights Reserved</Col>
        </Row>
        <Row>
          <Col md={12} style={{border:'1px solid #794c1d', marginBottom:'10px'}}></Col>
        </Row>
      </Container>
    </>
  );
};

export default Footer;

import React from 'react';
import AppBar from './AppNavbar';
import Footer from './Footer';
import Slides from './Slides';
import Main from './main';
const Home = () =>{
    return(
        <>
        <AppBar/>
        <Main/>
        <Slides/>
        <Footer />
        </>
    )
}
export default Home;
import React,{useEffect,useState} from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import GAuthLogout from './GAuthLogout';

export default function Profile() {
  const [anchorEl, setAnchorEl] = useState(null);
  const[profileImage, setProfileImage] = useState(null)
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const clientId = '551064649681-3iq4h8su27u8h80oqahtja8bhiu8fp2p.apps.googleusercontent.com';
  return (
    <>
      <Box sx={{  alignItems: 'start', textAlign: 'start' , display:'flex'}}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
          >
            <Avatar sx={{ width: 52, height: 52 }} src={localStorage.getItem('profileimageUrlObj')} ></Avatar>
            
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
       
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleClose}>
          My account
        </MenuItem>
        <MenuItem onClick={handleClose}>
        My Plots
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon className='GoogleLogout'>
           <GAuthLogout clientId={clientId} />
          </ListItemIcon>
        </MenuItem>
      </Menu>
    </>
  );
}

import React from "react";
import { Grid, Box, Paper } from "@mui/material";
import HeroImage from "../Images/hero.png";
import Slides from "./Slides";
import ButtonCards from "./custom/ButtonCards";

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
});

const Main = () => {


  return (
    <div>
      <Grid container spacing={0} >
        <Grid item md={0.5}></Grid>
        <Grid item md={11}>
          <Paper elevation={4} sx={{ backgroundColor: "#f6e9e7" }}>
            <Box
              component="img"
              sx={{
                height: " auto",
                padding: 0,
                margin: 0,
                maxWidth: "100%",
              }}
              src={HeroImage}
            />
          </Paper>
        </Grid>
        <Grid item md={0.5}></Grid>
      </Grid>
      <Grid container spacing={2}  sx={{marginTop:'10px'}}>
        <Grid item md={0.5}></Grid>
        <Grid item md={2.7}>
          <ButtonCards CardText="Monitor your plot or land remotely " CardButton="Monitor"/>
        </Grid>
        <Grid item md={2.8}>
          <ButtonCards CardText=" Check your eligibility to invest in indian real-estate" CardButton="Check"/>
        </Grid>
        <Grid item md={2.8}>
          <ButtonCards CardText="Best practices to transfer money in India" CardButton="Explore"/>
        </Grid>
        <Grid item md={2.7}>
          <ButtonCards CardText="Check hot regions to invest in India" CardButton="View"/>
        </Grid>
        <Grid item md={0.5}></Grid>
      </Grid>
     
    </div>
  );
};
export default Main;

import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  TextField,
  Button,
  Grid,
  InputLabel,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
  tableCellClasses,
  Table,
  Paper,
  Collapse,
  List,
  ListItem,
  ListItemText,
  IconButton
} from "@mui/material";
import BasicMap from "./BasicMap";
import Typography from "@mui/material/Typography";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
import { CreatePlotDetails } from "../APIservices/CallAPIs";

const AddPlotDetails = () => {
  const [formData, setFormData] = useState({
    registrationnumber: '',
    ownerName: '',
    address1: '',
    address2: '',
    address3: '',
    state: '',
    zipcode: '',
    plotName: '',
    plotSize: '',
    plotstatus: '',
    plottype: '',
    plotImage: '',
  });
  const [formErrors, setFormErrors] = useState({});
  const [expanded, setExpanded] = useState(false);
  const [GCrdntexpanded, setGCrdntExpanded] = useState(false);
  const [selectedCoordinates, setSelectedCoordinates] = useState([]);
  const [userLocation, setUserLocation] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [errors, setErrors] = useState([{ latitude: '', longitude: '' }]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ lat: latitude, lng: longitude });
        },
        (error) => {
          console.error("Error getting user location:", error.message);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);
  const handleRegCollapse = () => {
    setExpanded(!expanded);
  };
  const handleGeoCrdntCollapse = () => {
    setGCrdntExpanded(!GCrdntexpanded);
  };
  const handleGetLatlong = (data) => {
    setSelectedCoordinates(data);
  };
  console.log(selectedCoordinates);
  function createData(
    subscriptionId,
    feature,
    UpdateFrequency,
    ImageResolution,
    ChnageDetectionAlerts,
    HistoricalImageArchive,
    CustomizableReports,
    Price
  ) {
    return {
      subscriptionId,
      feature,
      UpdateFrequency,
      ImageResolution,
      ChnageDetectionAlerts,
      HistoricalImageArchive,
      CustomizableReports,
      Price,
    };
  }
  const [coordinates, setCoordinates] = useState([{ latitude: '', longitude: '' }]);
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#794c1d",
      color: "#f6e9e7",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  const rows = [
    createData(
      1,
      "Prime",
      "Monthly",
      "Standard",
      "No Alerts",
      "1 Year",
      "Bacic Summary",
      "$19/month"
    ),
    createData(
      2,
      "Pro",
      "Bi-Weekly",
      "High",
      "Potential encroachment, development around your plot (optional)",
      "2 years",
      "Detailed (analysis)",
      "$34/month"
    ),
    createData(
      3,
      "Premium",
      "Weekly",
      "Ultra-high",
      "Potential encroachment, development around your plot",
      "5 years",
      "Tailored (specific concerns)",
      "$55/month"
    ),
    createData(
      4,
      "OneTime",
      "One-time",
      "Ultra-high",
      "Not applicable",
      "Not applicable",
      "None",
      "$30/instance"
    ),
  ];
  const addNewTextBox = () => {
    setCoordinates([...coordinates, { latitude: '', longitude: '' }]);
    setErrors([...errors, { latitude: '', longitude: '' }]);
  };
  const handleTextBoxChange = (index, field, value) => {
    const newCoordinates = [...coordinates];
    newCoordinates[index][field] = value;
    setCoordinates(newCoordinates);

    // Perform validation
    const newErrors = [...errors];
    if (field === 'latitude') {
      newErrors[index].latitude = value.trim() === '' ? 'Latitude is required' : '';
    } else if (field === 'longitude') {
      newErrors[index].longitude = value.trim() === '' ? 'Longitude is required' : '';
    }
    setErrors(newErrors);
  };
  const handleDeleteTextBox = (index) => {
    const newCoordinates = [...coordinates];
    newCoordinates.splice(index, 1);
    setCoordinates(newCoordinates);

    const newErrors = [...errors];
    newErrors.splice(index, 1);
    setErrors(newErrors);
  };
  const handleRowClick = (row, index) => {
    setSelectedRow(index);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform any necessary actions with the form data
    const errors = {};
    // Example validation - check if required fields are filled
    if (!formData.registrationnumber) {
      errors.registrationnumber = 'RegistrationNumber is required';
    }
    if (!formData.ownerName) {
      errors.ownerName = 'Owner Name is required';
    }
    if (!formData.plotSize) {
      errors.plotSize = 'Plot Size is required';

    }
    if (!formData.address1) {
      errors.address1 = 'Address Line1 is required';
    }
    if (!formData.state) {
      errors.state = 'State is required';
    }
    if (!formData.zipcode) {
      errors.zipcode = 'zipcode is required';
    }
    setFormErrors(errors);
    if (Object.keys(errors).length > 0) return;
    const { registrationnumber, plotName, plottype, plotSize, plotImage, ownerName, plotstatus, address1, address2, address3, state, zipcode } = formData;
    let coordinates = selectedCoordinates.length > 0 ? selectedCoordinates[0] : coordinates;
    let coordinates_json = Object.assign({}, coordinates);
    let subscriptionId = rows[selectedRow].subscriptionId;
    console.log(subscriptionId);
    const data = {
      registrationnumber, plotName, plottype, plotSize, plotImage, ownerName, plotstatus, address1, address2, address3, state, zipcode, coordinates_json, subscriptionId
    };
    console.log(data);

    CreatePlotDetails(data)
      .then(response => {
        // Handle success
        console.log(response);
      })
      .catch(error => {
        // Handle error
        console.error(error);
      });
  }

  return (
    <>
      <Grid container spacing={2}>

        <Grid item md={12}>
          <Paper elevation={3} sx={{ padding: "20px", backgroundColor: '#ebd8d0 !important' }}>
            <form onSubmit={handleSubmit}>

              <Grid container spacing={2}>
                <Grid item md={8} sx={{ marginTop: "40px" }}>
                  <Typography variant="h6" sx={{ fontWeight: 600 }}>
                    {" "}
                    Please enter the Registration, Coordinates details , select the
                    Subscription and Submit{" "}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginTop: "20px" }}>
                <Grid item>
                  <Typography
                    variant="h6"
                    onClick={handleRegCollapse}
                    sx={{ cursor: "pointer" }}
                  >
                    Registration Details
                  </Typography>
                </Grid>
                {expanded ? (
                  <ExpandLess
                    onClick={handleRegCollapse}
                    sx={{
                      marginTop: "20px",
                      marginLeft: "30px",
                      cursor: "pointer",
                    }}
                  />
                ) : (
                  <ExpandMore
                    onClick={handleRegCollapse}
                    sx={{
                      marginTop: "20px",
                      marginLeft: "30px",
                      cursor: "pointer",
                    }}
                  />
                )}
                <Collapse in={expanded}>


                  <Grid
                    container
                    spacing={2}
                    sx={{ marginTop: "20px", marginBottom: "20px" }}
                  >
                    <Grid item md={3}>
                      <TextField
                        name="registrationnumber"
                        label="Registration Number"
                        value={formData.registrationnumber}
                        onChange={handleChange}
                        error={!!formErrors.registrationnumber}
                        helperText={formErrors.registrationnumber}
                      />
                    </Grid>
                    <Grid item md={3}>
                      <TextField
                        name="ownerName"
                        label="Owner Name"
                        value={formData.ownerName}
                        onChange={handleChange}
                        error={!!formErrors.ownerName}
                        helperText={formErrors.ownerName}
                      />
                    </Grid>
                    <Grid item md={3}>
                      <TextField
                        name="plotSize"
                        label="Plot Name"
                        value={formData.plotSize}
                        onChange={handleChange}
                        error={!!formErrors.plotSize}
                        helperText={formErrors.plotSize}
                      />
                    </Grid>
                    <Grid item md={3}>
                      <TextField
                        label="Address Line1"
                        name="address1"
                        value={formData.address1}
                        onChange={handleChange}
                        error={!!formErrors.address1}
                        helperText={formErrors.address1}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item md={3}>
                      <TextField
                        label="Address Line2"
                        name="address2"
                        value={formData.address2}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item md={3}>
                      <TextField
                        label="Address Line3"
                        name="address3"
                        value={formData.address3}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item md={3}>
                      <TextField
                        label="State"
                        name="state"
                        value={formData.state}
                        onChange={handleChange}
                        error={!!formErrors.state}
                        helperText={formErrors.state}
                      />
                    </Grid>
                    <Grid item md={3}>
                      <TextField

                        id="zipcode"
                        name="zipcode"
                        value={formData.zipcode}
                        label="Zip / Postal code"
                        error={!!formErrors.zipcode}
                        helperText={formErrors.zipcode}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>

                </Collapse>
              </Grid>
              <Grid container spacing={2} sx={{ marginTop: "20px" }}>
                <Grid item>
                  <Typography
                    variant="h6"
                    onClick={handleGeoCrdntCollapse}
                    sx={{ cursor: "pointer" }}
                  >
                    Coordinates Details
                  </Typography>
                </Grid>
                {GCrdntexpanded ? (
                  <ExpandLess
                    onClick={handleGeoCrdntCollapse}
                    sx={{
                      marginTop: "20px",
                      marginLeft: "30px",
                      cursor: "pointer",
                    }}
                  />
                ) : (
                  <ExpandMore
                    onClick={handleGeoCrdntCollapse}
                    sx={{
                      marginTop: "20px",
                      marginLeft: "30px",
                      cursor: "pointer",
                    }}
                  />
                )}
                <br />
                <Collapse in={GCrdntexpanded}>
                  <Grid
                    container
                    spacing={2}
                    sx={{ marginTop: "40px", marginBottom: "20px" }}
                  >
                    <Grid item>
                      <Typography variant="h6">
                        Add Geo Coordinates Manually
                      </Typography>
                    </Grid>
                  </Grid>

                  {coordinates.map((value, index) => (
                    <>
                      <Grid
                        container
                        spacing={2}
                        sx={{ marginTop: "20px", marginBottom: "20px" }}
                      >
                        <Grid item md={5}>
                          {" "}
                          <TextField
                            key={index}
                            value={value.latitude}
                            onChange={(event) => handleTextBoxChange(index, 'latitude', event.target.value)}
                            //label={`Latitude  ${index + 1}`}
                            label="min Latitude"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            error={!!errors[index].latitude}
                            helperText={errors[index].latitude}
                          />
                        </Grid>
                        <Grid item md={5}>
                          {" "}
                          <TextField
                            key={index}
                            value={value.longitude}
                            onChange={(event) => handleTextBoxChange(index, 'longitude', event.target.value)}
                            //label={`Longitude  ${index + 1}`}
                            label="min Longitude"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            error={!!errors[index].longitude}
                            helperText={errors[index].longitude}
                          />
                        </Grid>
                        
                        <Grid item md={5}>
                          {" "}
                          <TextField
                            key={index}
                            value={value.latitude}
                            onChange={(event) => handleTextBoxChange(index, 'latitude', event.target.value)}
                            //label={`Latitude  ${index + 1}`}
                            label="max Latitude"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            error={!!errors[index].latitude}
                            helperText={errors[index].latitude}
                          />
                        </Grid>
                        <Grid item md={5}>
                          {" "}
                          <TextField
                            key={index}
                            value={value.longitude}
                            onChange={(event) => handleTextBoxChange(index, 'longitude', event.target.value)}
                            //label={`Longitude  ${index + 1}`}
                            label="max Longitude"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            error={!!errors[index].longitude}
                            helperText={errors[index].longitude}
                          />
                        </Grid>
                        
                        
                      </Grid>
                    </>
                  ))}
                  {/* <Button variant="contained" onClick={addNewTextBox}>
                Add More
              </Button> */}
                  <Grid
                    container
                    spacing={2}
                    sx={{ marginTop: "20px", marginBottom: "20px" }}
                  >
                    <Grid item>
                      <Typography variant="h6">
                        Add Geo Coordinates using Map
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={2}
                    sx={{ marginTop: "20px", marginBottom: "20px" }}
                  >
                    <Grid item md={2}>
                      <InputLabel sx={{ fontWeight: 600 }}>Step 1:</InputLabel>
                    </Grid>
                    <Grid item md={10}>
                      <InputLabel>Search the plot location</InputLabel>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginTop: "20px", marginBottom: "20px" }}>
                    <Grid item md={2}>
                      <InputLabel sx={{ fontWeight: 600 }}>Step 2:</InputLabel>
                    </Grid>
                    <Grid item md={10}>
                      <InputLabel>
                        Use the "Draw" option to select coordinates
                      </InputLabel>
                    </Grid>
                  </Grid>
                 
                      <BasicMap getLatlong={handleGetLatlong} />
                  
                  <Grid
                    container
                    spacing={2}
                    sx={{ marginTop: "20px", marginBottom: "20px" }}
                  >
                    <Grid item md={3}>
                      <InputLabel sx={{ fontWeight: 600 }}>
                        Selected Coordinates:
                      </InputLabel>
                    </Grid>
                    <Grid item md={6}>

                      <List>
                        <ListItemText
                          primary={`Max Latitude: ${selectedCoordinates.maxLat==undefined ? "Not Selected Yet": selectedCoordinates.maxLat} `}
                        />
                        <ListItemText
                          primary={`Min Latitude: ${selectedCoordinates.minLat==undefined ? "Not Selected Yet": selectedCoordinates.minLat}`}
                        />
                        <ListItemText
                          primary={`Max Longitude: ${selectedCoordinates.maxLng==undefined ? "Not Selected Yet": selectedCoordinates.maxLng}`}
                        />
                        <ListItemText
                          primary={`Min Longitude: ${selectedCoordinates.minLat==undefined ? "Not Selected Yet": selectedCoordinates.minLat}`}
                        />
                      </List>
                    </Grid>
                  </Grid>
                </Collapse>
              </Grid>
              <Grid
                container
                spacing={2}
                sx={{ marginTop: "20px", marginBottom: "20px" }}
              >
                <Grid item>
                  <Typography variant="h6">Subscription Details</Typography>
                </Grid>
                <br />
                <Grid
                  container
                  spacing={2}
                  sx={{ marginTop: "20px", marginBottom: "20px" }}
                >
                  <Grid item md={1}></Grid>
                  <Grid item md={10}>
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 750 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell align="left">Feature</StyledTableCell>
                            <StyledTableCell align="left">
                              {" "}
                              Update Frequency
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              Image resolution
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              Change detection alerts
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              Historical Image archive
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              Customizable reports
                            </StyledTableCell>
                            <StyledTableCell align="left">Price</StyledTableCell>
                            <StyledTableCell align="left">Select</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows.map((row, index) => (
                            <TableRow
                              key={index}
                              sx={{
                                "&:last-child td, &:last-child th": { border: 0 },
                                backgroundColor:
                                  selectedRow === index ? "lightblue" : "inherit",
                              }}
                            >
                              <StyledTableCell
                                component="th"
                                scope="row"
                                style={{ fontWeight: 800, color: "indigo" }}
                              >
                                {row.feature}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {row.UpdateFrequency}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {row.ImageResolution}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {row.ChnageDetectionAlerts}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {row.HistoricalImageArchive}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {row.CustomizableReports}
                              </StyledTableCell>
                              <StyledTableCell
                                align="left"
                                style={{ fontWeight: 800, color: "indigo" }}
                              >
                                {row.Price}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                <Button
                                  variant="outlined"
                                  endIcon={<SendIcon />}
                                  onClick={() => handleRowClick(row, index)}
                                  sx={{
                                    color: "darkgreen",
                                    border: "1px solid darkgreen",
                                  }}
                                >
                                  {" "}
                                  Select
                                </Button>
                              </StyledTableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                  <Grid item md={1}></Grid>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                sx={{ marginTop: "20px", marginBottom: "20px" }}
              >
                <Grid item md={4.5}></Grid>
                <Grid item md={3}>
                  <Button
                    type="submit"
                    variant="contained"
                    className="ButtonCardAction"
                    sx={{ width: "100%" }}
                  >
                    Submit
                  </Button>
                </Grid>
                <Grid item md={4.5}></Grid>
              </Grid>

            </form>
          </Paper>
        </Grid>

      </Grid>
    </>
  );
};

export default AddPlotDetails;
